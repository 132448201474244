import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Avatar from '@material-ui/core/Avatar';
import ListSubheader from '@material-ui/core/ListSubheader';

import AboutMe from "../AboutMe";
import { loadFromFile } from '../../utils/http';

const styles = themes => ({
    page_title: {
        textTransform: "capitalize",
        color: "black",
        fontSize: "24px",
        paddingBottom: "20px",
        paddingTop: "20px",
        textAlign: "center"
    },
    profile_image: {
        width: 160,
        height: 160,
        margin: '30px auto 0px',
        border: '5px solid #0D9DDB'
    },
    contact_info: {
        textAlign: "center"
    },
    contact_line: {
        marginBottom: 10
    },
    grow: {
        flexGrow: 1
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)'
    },
    hidden: {
        display: "none"
    }
});

class Home extends Component {
    constructor() {
        super();
        this.state = {
            projects: []
        };
    }

    componentDidMount = () => {
        this.loadProjects();
    }

    updateProjects = (projects) => {
        this.setState(() => ({
            projects: projects
        }))
    }

    loadProjects = () => {
        const { updateProjects } = this;

        loadFromFile("/content/projects/home.json", (response) => {
            const { projects } = response.data;
            updateProjects(projects);
        });
    }

    render() {
        const { classes } = this.props
        const { projects } = this.state

        return (
            <div className="content_container">
                <Paper className="content">
                    <div>
                        <ListSubheader className={classes.page_title + " module_header " + classes.hidden} disableSticky>About Me</ListSubheader>
                    </div>
                    <Grid container spacing={40} direction="row">
                        <Grid item xs={12}>
                            <Avatar className={classes.profile_image} src="/assets/profile.jpg" />
                        </Grid>
                        <Grid item xs={12}>
                            <AboutMe />
                        </Grid>
                        <Grid item xs={12}>
                            <p>
                                My name is Calvin. I graduated from Berkeley with a Master of Engineering degree in Mechanical Engineering, with a focus on Robotics and Controls of Autonomous Systems. As part of my graduate degree I took classes that give me a foundation in modern control theory, learning about topics that include optimal control, MPC, and Kalman filtering. The knowledge was later applied in projects working in teams of 3-4 people to create control systems for various simulated systems in Python or Matlab. To broaden my knowledge, I also took courses in machine learning, where I learned the basics of designing different machine learning models in Python.
                            </p>
                            <p>
                                My undergraduate life was spent at UCLA, where I earned a Bachelor's degree in Mechanical Engineering. During this time, I worked as an intern at multiple tech startups, developing a diverse set of skills ranging from full stack software development to various manufacturing techniques. In addition to my internship experience, I was actively involved in my school’s combat robotics team, which designed and built three-pound and sixty-pound robots for competition every year. During my third undergraduate year, I took on a leadership role, mentoring first and second year students that joined the team.
                            </p>
                        </Grid>
                    </Grid>
                    <div>
                        <ListSubheader className={classes.page_title + " module_header"} disableSticky>Projects</ListSubheader>
                    </div>
                    <GridList cols={3} cellHeight={300}>
                    {
                        projects.map((project) => (
                            <GridListTile key={project.title} className={classes.project}>
                                <img src={project.img} alt={project.title} />
                                <GridListTileBar
                                    title={project.title}
                                    subtitle={project.content}
                                    actionIcon={
                                        <IconButton className={classes.icon} href={project.url}>
                                            <InfoIcon />
                                        </IconButton>
                                    } />
                            </GridListTile>
                        ))
                    }
                    </GridList>
                    <div>
                        <ListSubheader className={classes.page_title + " module_header"} disableSticky>Contact Me</ListSubheader>
                    </div>
                    <Grid container spacing={40}>
                        <Grid item className={classes.contact_info} xs={12}>
                            <div className={classes.contact_line}>
                                Email: <a href="mailto:calvinshih2004@gmail.com">calvinshih2004@gmail.com</a>
                            </div>
                            <div className={classes.contact_line}>
                                Linkedin: <a href="www.linkedin.com/in/calvin-shih-94b841109">www.linkedin.com/in/calvin-shih-94b841109</a>
                            </div>
                            <div className={classes.contact_line}>
                                <a href="https://drive.google.com/file/d/1Xu1iCPZJCHkSqRHqZ48cHSQ595i3GYC6/view">Resume</a>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }

}

export default withStyles(styles)(Home);
