import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListSubheader from '@material-ui/core/ListSubheader';

const parse = require('html-react-parser');

const styles = themes => ({
    text_content: {
        width: "90%",
        maxWidth: "900px",
        margin: "0px auto",
        display: "block"
    }
});

class TextModule extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        const { sub_header, content, classes } = this.props;

        return (
            <Grid item xs={12}>
                <ListSubheader className="module_header" disableSticky>{sub_header}</ListSubheader>
                <div className={classes.text_content}>
                    {parse(content)}
                </div>
            </Grid>
        )
    }
}

export default withStyles(styles)(TextModule);
