import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RemoveIcon from '@material-ui/icons/Remove';
import ListSubheader from '@material-ui/core/ListSubheader';

const styles = themes => ({
    list: {
        width: "90%",
        maxWidth: "900px",
        margin: "0px auto",
        display: "block"
    }
});


class ListModule extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        const { content, sub_header, classes } = this.props;

        return (
            <Grid item xs={12}>
                <List className={classes.list}>
                    <ListSubheader className="module_header" disableSticky>{sub_header}</ListSubheader>
                    {
                        content.map(item =>
                            <ListItem key={item}>
                                <ListItemIcon>
                                    <RemoveIcon/>
                                </ListItemIcon>
                                <ListItemText>
                                    {item}
                                </ListItemText>
                            </ListItem>
                        )
                    }
                </List>
            </Grid>
        )
    }
}

export default withStyles(styles)(ListModule);
