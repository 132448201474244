import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import Home from './pages/Home';
import ContentPage from './pages/ContentPage';
import TopicHome from './pages/TopicHome';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    content: {
        paddingTop: "64px",
        width: "100%",
        height: "100vh",
        boxSizing: "border-box",
        overflowX: "hidden"
    },
    header: {
        height: "64px"
    }
})

class App extends Component {
    constructor() {
        super();
        this.state = {
            headers: []
        };
    }

    componentDidMount = () => {
        this.updateHeaders()
    }

    updateHeaders = () => {
        var elements = Array.from(document.getElementsByClassName("module_header"))

        if (elements.length <= 0) {
            setTimeout(this.updateHeaders, 200)
        }

        this.setState(() => ({
            headers: elements
        }))
    }

    scrollTo = (index) => () => {
        const { headers } = this.state
        headers[index].parentElement.scrollIntoView({ behavior: 'smooth' })
    }

    render() {
        const { classes } = this.props
        const { headers } = this.state

        return (
            <Router>
                <div>
                    <AppBar className="mui-fixed" color="inherit">
                        <Toolbar className={classes.header}>
                            <Button href="/">
                                Home
                            </Button>
                            {
                                headers.map((header, index) => (
                                    header.textContent ? <Button key={header.textContent} onClick={this.scrollTo(index)}>
                                        {header.textContent}
                                    </Button> : null
                                ))
                            }
                        </Toolbar>
                    </AppBar>
                    <div className={classes.content}>
                        <Route exact path="/" component={Home} />
                        <Route path="/t/:topic/:key" component={ContentPage} />
                        <Route exact path="/t/:topic" component={TopicHome} />
                    </div>
                </div>
            </Router>
        );
    }

}

export default withStyles(styles)(App);
