import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { loadFromFile } from '../../utils/http';

import TextModule from '../modules/TextModule'
import ListModule from '../modules/ListModule'
import ImgModule from '../modules/ImgModule'
import CodeModule from '../modules/CodeModule'

const styles = themes => ({
    page_title: {
        textTransform: "capitalize",
        color: "black",
        fontSize: "24px",
        paddingBottom: "20px",
        paddingTop: "20px"
    }
});

class ContentPage extends Component {
    constructor(props) {
        super(props);

        const { match } = props;

        this.state = {
            topic: match.params.topic,
            key: match.params.key,
            content: {}
        };
    }

    componentDidMount = () => {
        this.loadContent();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevState.topic !== this.state.topic ||
            prevState.key !== this.state.key) {
            this.loadContent();
        }
    }

    updateContent = (content) => {
        this.setState(() => ({
            content: content
        }))
    }

    loadContent = () => {
        const { topic, key } = this.state;
        const { updateContent } = this;

        loadFromFile("/content/" + topic + "/" + key + ".json", (response) => {
            updateContent(response.data);
        });
    }

    renderModule = (module, key) => {
        switch(module.type) {
            case "text":
                return <TextModule key={key} {...module} />
            case "list":
                return <ListModule key={key} {...module} />
            case "img":
                return <ImgModule key={key} {...module} />
            case "code":
                return <CodeModule key={key} {...module} />
            default:
                console.log("This is an invalid module type.")
                return "";
        }
    }

    render() {
        const { content } = this.state;
        const { classes } = this.props;
        const { renderModule } = this;

        return (
            <div className="content_container">
                <Paper className="content">
                    <div>
                        <ListSubheader className={classes.page_title} disableSticky>{content.title}</ListSubheader>
                    </div>
                    <Grid container spacing={40}>
                        {
                            content.content ? content.content.map((module, key) => {
                                return renderModule(module, key)
                            }) : ""
                        }
                    </Grid>
                </Paper>
            </div>
        );
    }

}

export default withStyles(styles)(ContentPage);
