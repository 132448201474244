import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListSubheader from '@material-ui/core/ListSubheader';

const parse = require('html-react-parser');

const styles = themes => ({
    img: {
        maxHeight: "400px",
        margin: "0px auto",
        display: "block"
    },
    caption: {
        width: "90%",
        display: "block",
        maxWidth: "600px",
        margin: "10px auto 0px auto",
        color: "rgba(0, 0, 0, 0.54)"
    }
});


class ImgModule extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        const { sub_header, src, caption, classes } = this.props;

        return (
            <Grid item xs={12}>
                <ListSubheader className="module_header" disableSticky>{sub_header}</ListSubheader>
                <img className={classes.img} src={src} alt="hings"/>
                <div className={classes.caption}>
                    {parse(caption)}
                </div>
            </Grid>
        )
    }
}

export default withStyles(styles)(ImgModule);
