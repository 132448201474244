import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typist from 'react-typist';

const styles = themes => ({
    main: {
        width: "100%",
        textAlign: "center",
        margin: '30px 0px',
        fontSize: "50px"
    },
    animated: {
        display: "inline-block",
        textDecoration: "underline",
        textDecorationColor: "#0D9DDB"
    }
})

class AboutMe extends Component {
    constructor() {
        super();

        this.state = {
            words: [
                "software developer",
                "builder",
                "mechanical engineer",
                "learner"
            ]
        }

        this.state.current_word = Math.floor(Math.random() * this.state.words.length)
    }

    changeWord = () => {
        const { words, current_word } = this.state

        var nextWord = Math.floor(Math.random() * words.length)

        while (nextWord === current_word) {
            nextWord = Math.floor(Math.random() * words.length)
        }

        this.setState(() => ({
            current_word: nextWord
        }))
    }

    render () {
        const { classes } = this.props
        const { words, current_word } = this.state

        return (
            <div className={classes.main}>
                I am a <Typist key={words[current_word]} className={classes.animated} cursor={{show: false}} onTypingDone={this.changeWord}>
                    <Typist.Delay ms={1000} />
                    {words[current_word]}
                    <Typist.Backspace count={words[current_word].length} delay={1500} />
                </Typist>
            </div>
        )
    }
}

export default withStyles(styles)(AboutMe);
