import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListSubheader from '@material-ui/core/ListSubheader';
import Highlight from 'react-highlight'

const styles = themes => ({
    code_content: {
        width: "90%",
        maxWidth: "900px",
        margin: "0px auto",
        display: "block"
    }
});

class CodeModule extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        const { sub_header, content, classes } = this.props;

        return (
            <Grid item xs={12}>
                <ListSubheader className="module_header" disableSticky>{sub_header}</ListSubheader>
                <Highlight className={"python " + classes.code_content}>
                    {content}
                </Highlight>
            </Grid>
        )
    }
}

export default withStyles(styles)(CodeModule);
