import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListSubheader from '@material-ui/core/ListSubheader';
import GridListTileBar from '@material-ui/core/GridListTileBar';


import { loadFromFile } from '../../utils/http';

const styles = themes => ({
    list_title: {
        textTransform: "capitalize",
        color: "black",
        fontSize: "24px",
        paddingBottom: "20px",
        paddingTop: "20px"
    }
});

class TopicHome extends Component {
    constructor(props) {
        super(props);

        const { match } = props;

        this.state = {
            topic: match.params.topic,
            topic_list: []
        };
    }

    componentDidMount = () => {
        this.loadTopicList();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevState.topic !== this.state.topic) {
            this.loadTopicList();
        }
    }

    updateList = (list) => {
        this.setState(() => ({
            topic_list: list
        }))
    }

    loadTopicList = () => {
        const { topic } = this.state;
        const { updateList } = this;

        loadFromFile("/content/topic_lists/" + topic + ".json", (response) => {
            const { list } = response.data;
            updateList(list);
        });
    }

    render() {
        const { classes } = this.props;
        const { topic, topic_list } = this.state;

        return (
            <div className="content_container">
                <Paper className="content">
                    <ListSubheader className={classes.list_title} disableSticky>{topic}</ListSubheader>
                    <GridList cols={2} cellHeight={250}>
                        {
                            topic_list.map((title, key) => (
                                <GridListTile key={key} component={Link} to={"/t/projects/" + key}>
                                    <img src={"/assets/" + topic + "/" + key + "/cover.png"} alt={topic + " " + key}></img>
                                    <GridListTileBar title={title}/>
                                </GridListTile>
                            ))
                        }
                    </GridList>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(TopicHome);
